import { Component, Vue } from 'vue-property-decorator';
import Layout from '@/components/layouts/main.vue';
import { Action } from 'vuex-class';
import ActivitiesForm from '@/components/pages/activities/activities-form/activitiesForm.vue';
import { ActivityReferenceCreateRequest } from '@/types/request/activity-request.types';
import { ActivityType } from '@/types/api/info/activity.type';

@Component({
  components: {
    Layout,
    ActivitiesForm
  },
})
export default class ActivitiesCreate extends Vue {
  @Action('createActivity', { namespace: 'activity' })
  createActivity!: (params: ActivityReferenceCreateRequest) => Promise<ActivityType>;

  onApply(params: ActivityReferenceCreateRequest) {
    return this.createActivity(params)
      .then(() => {
        this.$router.push({ name: 'activities' });
      });
  }
}
