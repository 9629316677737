import { Component, Prop, Vue } from 'vue-property-decorator';
import { ValidationObserver } from 'vee-validate';
import BTextInputValidation from '@/components/ui/inputs/b-text-input-validation/bTextInputValidation.vue';
import BFileInputValidation from '@/components/ui/inputs/b-file-input-validation/bFileInputValidation.vue';
import { Action, Getter } from 'vuex-class';
import { LanguageType } from '@/types/api/info/language.type';
import LocaleUpdater from '@/components/ui/locale-updater/localeUpdater.vue';
import { LocaleUpdateEvent } from '@/components/ui/locale-updater/localeUpdater';
import { ActivityCard } from '@/store/modules/activity/actions';
import {
  ActivityCreateLocaleRequest,
  ActivityReferenceCreateRequest,
  ActivityReferenceUpdateRequest, ActivityUpdateLocaleRequest
} from '@/types/request/activity-request.types';
import { ActivityType } from '@/types/api/info/activity.type';
import { ActivityLocaleType } from '@/types/api/info/activity-locale.type';
import BSelectInputValidation from '@/components/ui/inputs/b-select-input-validation/bSelectInputValidation.vue';
import FormBlock from '@/components/ui/form-block/form-block.vue';
import PageTitle from '@/components/ui/page-title/pageTitle.vue';
import ShowContent from '@/components/ui/modals/showContent.vue';
import { MODAL_CONFIG } from '@/helpers/app.helper';

const getTitle = () => window.location.href.includes('/activities/update') ? 'activity.update' : 'activity.add';

@Component({
  name: 'ActivitiesForm',
  page: {
    title: getTitle()
  },
  components: {
    ValidationObserver,
    BTextInputValidation,
    BFileInputValidation,
    BSelectInputValidation,
    LocaleUpdater,
    FormBlock,
    PageTitle,
  },
})
export default class ActivitiesForm extends Vue {
  title = getTitle();
  tryingToUpdate = false;
  image = null;
  imageUrl = '';
  name = '';
  language = '';
  locales: ActivityLocaleType[] = [];

  @Prop({ required: false })
  activity?: ActivityType;

  @Prop({ required: true })
  updateCallback!: (params: ActivityReferenceCreateRequest | ActivityReferenceUpdateRequest) => Promise<ActivityType>;

  @Action('createLocale', { namespace: 'activity' })
  createLocale!: (data: ActivityCard<ActivityCreateLocaleRequest>) => Promise<ActivityLocaleType>;

  @Action('updateLocale', { namespace: 'activity' })
  updateLocale!: (data: ActivityCard<ActivityUpdateLocaleRequest>) => Promise<ActivityLocaleType>;

  @Getter('getLanguages', { namespace: 'info' })
  languages?: LanguageType[];

  @Getter('getCurrentLang', { namespace: 'lang' })
  currentLang!: string;

  created(): void {
    if (this.activity) {
      this.imageUrl = this.activity.image;
      this.name = this.activity.name;
      this.locales = [...this.activity.locales];
    }
  }

  onImageUpload(event) {
    this.image = event;
    this.imageUrl = '';
  }

  onApply() {
    if (!this.tryingToUpdate) {
      this.tryingToUpdate = true;
      this.updateCallback({
        image: this.image as any,
        ...(!this.activity ? { name: this.name } : {}),
        ...(!this.activity ? { language: this.language } : {}),
      })
        .then(() => {
          this.tryingToUpdate = false;
        })
        .catch(() => {
          this.tryingToUpdate = false;
        });
    }
  }

  get languageOptions() {
    return [
      { value: '', text: '---' },
      ...(this.languages || []).map(item => ({ value: item.code, text: item.code.toUpperCase() }))
    ];
  }

  onUpdateLocale(event: LocaleUpdateEvent) {
    const promise = event.locale
      ? this.updateLocale({ id: event.locale.id, params: event.editedLocale as any })
      : this.createLocale({ id: this.activity!.id, params: event.editedLocale as any });

    promise.then(result => {
      this.locales = this.locales.filter(item => item.languageId !== event.language.id);
      this.locales.push(result);
    });
  }

  showContent(title: string, content: string) {
    this.$modal.show(ShowContent, { content, title, }, MODAL_CONFIG);
  }

  get activityName() {
    const language = this.languages?.find(item => item.code === this.currentLang);
    const locale = language ? (this.activity?.locales || []).find(item => item.languageId === language.id) : '';
    return locale ? locale.name : '';
  }
}
